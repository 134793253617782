<!-- Displays the 'Worst speed restrictions' card -->

<template>
    <Card id="worst-speed-restrictions-container">
        <template #header-left>
            Top speed restrictions <span class="pipe">|</span> {{ activeTitle }}
        </template>
        <template #header-central>
            <div class="key">Colour Key:</div>
            <div class="key esr">ESR</div>
            <div class="key tsr">TSR</div>
        </template>
        <template #header-right>
            <MultiSelectDropdown title="SR Type" class="filter"
                v-model:filterValues="esrTsrFilter"
                :itemList="['TSR', 'ESR']"
                :width="6"
            />
            Time loss:
            <Button @click="buttonClicked('daily')" :class="{active: buttonIsActive('daily')}">Daily</Button>
            <Button @click="buttonClicked('cumulative')" :class="{active: buttonIsActive('cumulative')}">Cumulative</Button>
        </template>
        <template #body>
            <div v-if="loading">
            LOADING
            </div>
            <div v-else class="scroll-content">
                <DataTable :value="topSpeedRestrictionsData[active]" :autoLayout="true">
                    <template #empty>
                        No speed restrictions found.
                    </template>
                    <Column field="reference">
                        <template #body="{data}">
                            <span @click="referenceNumberClicked(data['reference'])" class="bold reference"
                                :class="{'esr' : isEsr(data.type), 'tsr': !isEsr(data.type)} "> 
                                {{ data.reference }} 
                            </span>
                        </template>
                    </Column>
                    <Column field="location_from">
                        <template #body="{data}">
                            <StyledFromTo fromSymbol="filled-circle-light" toSymbol="filled-square-dark" class="fromto">
                                <template #from>
                                    {{data['location_from']}}
                                </template>
                                <template #to>
                                    {{data['location_to']}}
                                </template>
                            </StyledFromTo>
                        </template>
                    </Column>
                    <Column :field="active + '_impact_minutes'">
                        <template #body="{data}">
                            <span class="impact-cell">
                                <span class="bold"> {{ formatMinutes(data[active + '_impact_minutes']) }} </span>
                                <span v-if="active === 'daily'"> time loss per day </span>
                                <span v-else> cumulative time loss </span>
                            </span>
                        </template>
                    </Column>
                    <Column field="reason">
                        <template #body="{data}">
                            <div v-html="formatReason(data.reason)"></div>
                        </template>
                    </Column>
                    <Column>
                        <!-- BM: Add here code to check user input before placing "Missing removal date"
                        - If (data.to_date == null), run a query to check if user_input table has estimated date.
                        -- If user_input table has no estimated completion date, then return "No actions"
                        -->
                        <template #body="{data}">
                            <span v-if="data.to_date == null && !hasUserInputData[active][data.reference]" class="red status">Missing removal date</span>
                            <span v-else-if="data.to_date == null && hasUserInputData[active][data.reference]" class="yellow status">No actions</span>
                            <span v-else-if="hasNoActionsData[active][data.reference]" class="yellow status">No actions</span>
                            <span v-else class="green status">Removal confirmed</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from "../common/Card.vue"
import Button from "../common/Button.vue"
import DataTable from "primevue/datatable/sfc";
import Column from "primevue/column/sfc";
import axios from "axios";
import StyledFromTo from '../common/StyledFromTo.vue'
import rnc from "src/functions/reference_number_conversion.js"
import format from "src/functions/formatter.js"
import checker from "src/functions/sr_type_checker.js"
import MultiSelectDropdown from "src/components/home/common/MultiSelectDropdown.vue"

export default {
    name: "WorstSpeedRestrictions",
    components: {
        Card,
        Button,
        DataTable,
        Column,
        StyledFromTo,
        MultiSelectDropdown
    },
    data() {
        return {
            active: "daily",
            topSpeedRestrictionsData: {
                "daily": null,
                "cumulative": null
            },
            hasNoActionsData: {
                "daily": null,
                "cumulative": null
            },
            hasUserInputData: {
                "daily": null,
                "cumulative": null
            },
            loading: true,
            esrTsrFilter: []
        }
    },
    computed: {
        activeRegion() {
            return this.$store.state.activeRegion
        },
        activeRoute() {
            return this.$store.state.activeRoute
        },
        activeToc() {
            return this.$store.state.activeToc
        },
        activeType() {
            return this.$store.state.activeType
        },
        activeTitle() {
            if (this.activeType === "route") {
                return this.activeRoute
            } else if (this.activeType === "region") {
                return this.activeRegion
            } else {
                return this.activeToc
            }
        }
    },
    watch: {
        esrTsrFilter: {
            handler() {
                if (this.activeType === "region") {
                    this.getRegionData()
                }
                else if (this.activeType === "route") {
                    this.getRouteData()
                }
                else if (this.activeType === "toc") {
                    this.getTocData()
                }
            },
            deep: true,
        },
        activeRegion: function() {
            if (this.activeType === "region") {
                this.getRegionData()
            }
        },
        activeRoute: function() {
            if (this.activeType === "route") {
                this.getRouteData()
            }
        },
        activeToc: function() {
            if (this.activeType === "toc") {
                this.getTocData()
            }
        },
        activeType: function() {
            if (this.activeType === "route") {
                this.getRouteData()
            } else if (this.activeType === "region") {
                this.getRegionData()
            } else {
                this.getTocData()
            }
        }
    },
    methods: {
        createEsrTsrFilterStringForApi(esrTsrFilter) {
            /*
            * Converts esrTsrFilter to a string to send as API paramter
            *
            * If filter is empty it's populated with both 'TSR' and 'ESR' so
            * that all results are returned
            */
            if (esrTsrFilter.length === 0) return JSON.stringify(["TSR", "ESR"])

            return JSON.stringify(esrTsrFilter)
        },
        buttonClicked(button_type) {
            if (button_type != this.active) {
                this.active = button_type
            }
        },
        buttonIsActive(button_type) {
            if (button_type === this.active) {
                return true
            }
            else {
                return false
            }
        },
        formatReason(string) {
            return format.formatReason(string)
        },
        isEsr(type) {
            return checker.isEsr(type)
        },
        formatMinutes(minutes) {
            if (minutes == null) {
                minutes = "Unknown"
            }
            else {
                minutes = minutes.toLocaleString("en-UK")
                if (minutes == 1) {
                    minutes += " minute"
                }
                else {
                    minutes += " minutes"
                }
            }
            return minutes
        },
        referenceNumberClicked(referenceNumber) {
            // set router to IndividualContent with reference as parameter
            referenceNumber = rnc.referenceToUriReference(referenceNumber)
            this.$router.push(`/home/individual/overview/${referenceNumber}`)
        },
        async getUserInputData(daily_or_cumulative) {
            const data = this.topSpeedRestrictionsData[daily_or_cumulative]
            let hasUserInputData = {}
            for (const row of data) {
                hasUserInputData[row.reference] = await this.checkUserInputDataPresence(row.reference)
            }
            this.hasUserInputData[daily_or_cumulative] = hasUserInputData            
        },
        async checkUserInputDataPresence(referenceNo) {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/user-input/get-user-input`,
                method: "GET",
                params: {reference: referenceNo},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                return response.data.estimated_removal_date != null
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },        
        async getActionsCounts(daily_or_cumulative) {
            const data = this.topSpeedRestrictionsData[daily_or_cumulative]
            let hasNoActionsData = {}
            for (const row of data) {
                hasNoActionsData[row.reference] = await this.hasNoActions(row.reference)
            }
            this.hasNoActionsData[daily_or_cumulative] = hasNoActionsData
        },
        async hasNoActions(reference) {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/get-restriction-actions-count`,
                method: "GET",
                params: {reference: reference},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                return response.data.open_count === 0
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getRegionData() {
            this.loading = true
            let loading_count = 0
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/top-daily-region-speed-restrictions`,
                method: "GET",
                params: {
                    region: this.$store.state.activeRegion,
                    esrTsrFilter: this.createEsrTsrFilterStringForApi(
                        this.esrTsrFilter
                    ),
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(async response => {
                this.topSpeedRestrictionsData["daily"] = response.data
                await this.getActionsCounts("daily")
                await this.getUserInputData("daily")
                loading_count +=1
                if (loading_count >= 2) {
                    this.loading = false
                    this.removeHeader()
                }
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/top-cumulative-region-speed-restrictions`,
                method: "GET",
                params: {
                    region: this.$store.state.activeRegion,
                    esrTsrFilter: this.createEsrTsrFilterStringForApi(
                        this.esrTsrFilter
                    ),
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(async response => {
                this.topSpeedRestrictionsData["cumulative"] = response.data
                await this.getActionsCounts("cumulative")
                await this.getUserInputData("cumulative")
                loading_count +=1
                if (loading_count >= 2) {
                    this.loading = false
                    this.removeHeader()
                }
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getRouteData() {
            this.loading = true
            let loading_count = 0
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/top-daily-speed-restrictions`,
                method: "GET",
                params: {
                    route: this.$store.state.activeRoute,
                    esrTsrFilter: this.createEsrTsrFilterStringForApi(
                        this.esrTsrFilter
                    ),
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(async response => {
                this.topSpeedRestrictionsData["daily"] = response.data
                await this.getActionsCounts("daily")
                await this.getUserInputData("daily")
                loading_count +=1
                if (loading_count >= 2) {
                    this.loading = false
                    this.removeHeader()
                }
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/top-cumulative-speed-restrictions`,
                method: "GET",
                params: {
                    route: this.$store.state.activeRoute,
                    esrTsrFilter: this.createEsrTsrFilterStringForApi(
                        this.esrTsrFilter
                    ),
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(async response => {
                this.topSpeedRestrictionsData["cumulative"] = response.data
                await this.getActionsCounts("cumulative")
                await this.getUserInputData("cumulative")
                loading_count +=1
                if (loading_count >= 2) {
                    this.loading = false
                    this.removeHeader()
                }
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getTocData() {
            this.loading = true
            let loading_count = 0
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/top-daily-toc-speed-restrictions`,
                method: "GET",
                params: {
                    toc: this.$store.state.activeToc,
                    esrTsrFilter: this.createEsrTsrFilterStringForApi(
                        this.esrTsrFilter
                    ),
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(async response => {
                this.topSpeedRestrictionsData["daily"] = response.data
                await this.getActionsCounts("daily")
                await this.getUserInputData("daily")
                loading_count +=1
                if (loading_count >= 2) {
                    this.loading = false
                    this.removeHeader()
                }
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/top-cumulative-toc-speed-restrictions`,
                method: "GET",
                params: {
                    toc: this.$store.state.activeToc,
                    esrTsrFilter: this.createEsrTsrFilterStringForApi(
                        this.esrTsrFilter
                    ),
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(async response => {
                this.topSpeedRestrictionsData["cumulative"] = response.data
                await this.getActionsCounts("cumulative")
                await this.getUserInputData("cumulative")
                loading_count +=1
                if (loading_count >= 2) {
                    this.loading = false
                    this.removeHeader()
                }
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        removeHeader() {
            // wait's for table to exist, then removes header
            this.$nextTick(function() {
                if (document.querySelector("#worst-speed-restrictions-container .p-datatable-thead")) {
                    let headerElement = document.querySelector("#worst-speed-restrictions-container .p-datatable-thead")
                    headerElement.remove()
                }
            })
        }
    },
    created() {
        if (this.activeType === "route") {
            this.getRouteData()
        } else if (this.activeType === "region") {
            this.getRegionData()
        } else {
            this.getTocData()
        }
    }
}
</script>

<style scoped>
::v-deep .body {
    padding-right: 0;
}
:deep(.p-datatable-tbody > tr) {
    height: 4rem;
}
.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
.bold {
    font-weight: bold;
}
.esr {
    color: var(--colour-7);
}
.tsr {
    color: var(--colour-1);
}
.reference {
    font-size: 1.1rem;
    border: 0;
}
.reference:hover {
    cursor: pointer;
    border-style: solid;
    border-bottom-width: 2px;
    border-color: var(--colour-1) !important;
}
.pipe {
    font-weight: normal;
}

.fromto ::v-deep .from, .fromto ::v-deep .to {
    margin-bottom: 0;
    font-size: 1rem;
}
.impact-cell {
    white-space: nowrap;
}
.filter {
    margin-right: 1rem;
}
.status {
    display: inline-block;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    border-style: solid;
    border-width: 2px;
    border-radius: 0.7rem;
    white-space: nowrap;
    text-align: center;
    width: 11.5rem;
}
.status.red {
    color: var(--colour-7);
    border-color: var(--colour-7);
}
.status.yellow {
    color: var(--colour-5);
    border-color: var(--colour-5);
}
.key {
    display: inline; 
    margin-right: 0.7em;
}
.status.green {
    color: var(--colour-12);
    border-color: var(--colour-12);
}
#worst-speed-restrictions-container ::v-deep .body {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
}
#worst-speed-restrictions-container ::v-deep .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 1rem 0.5rem 1rem;
}
#worst-speed-restrictions-container ::v-deep .p-datatable .p-datatable-tbody > tr > td:first-child{
    padding-left: 0.5rem;
}
#worst-speed-restrictions-container ::v-deep .p-datatable .p-datatable-tbody > tr > td:last-child{
    padding-right: 0;
}
</style>
