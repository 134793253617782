<!-- Displays content for the Speed Restriction Register component  -->

<template>
    <Card>
        <template #header-left>
            Speed restriction register
        </template>
        <template #header-central>
            <div class="key">Colour Key:</div>
            <div class="key esr">ESR</div>
            <div class="key tsr">TSR</div>
        </template>
        <template #header-right>
            <DownloadSpeedRestrictionsTableButton :filters="filters"/>
        </template>
        <template #body>
            <div v-if="loading">
            LOADING
            </div>
            <div v-else class="scroll-content">
                <div class="filters-row">
                    <b>Filters</b>
                    <MultiSelectDropdown title="Ref No." class="filter"
                        v-model:filterValues="filters.reference.value"
                        :itemList="referenceNumbers"
                        :width="8"
                    />
                    <MultiSelectDropdown title="Delivery unit" class="filter"
                        v-model:filterValues="filters.delivery_unit.value"
                        :itemList="duOptions"
                        :width="8"
                    />
                    <MultiSelectDropdown title="Reason" class="filter"
                        v-model:filterValues="filters.reason.value"
                        :itemList="reasonOptions"
                        :width="10"
                    />
                    <MultiSelectDropdown title="SR Type" class="filter"
                        v-model:filterValues="filters.type.value"
                        :itemList="typeOptions"
                        :width="6"
                    />
                </div>
                <DataTable :value="data" :autoLayout="true"
                        class="p-datatable-sm speeds-table"
                        v-model:filters="filters" filterDisplay="menu"
                        sortField="daily_impact" :sortOrder="-1"
                >
                    <template #empty>
                        No speed restrictions found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <Column field="reference" header="Ref No.">
                        <template #body="{data}">
                            <button @click="referenceNumberClicked(data['reference'])" class="ref-button"
                                :class="{'active': isActiveSpeedRestriction(data.reference), 'esr' : isEsr(data.type), 'tsr' : !isEsr(data.type)}"
                                @mouseenter="setHoverLabelIfOverflowing" 
                            >
                                {{putNewlineInReference(data['reference'])}}
                            </button>
                        </template>
                    </Column>
                    <Column field="location_from" header="Location">
                        <template #body="{data}">
                            <StyledFromTo
                                fromSymbol="filled-circle-light"
                                toSymbol="filled-square-dark"
                                :noWrap="true"
                                :maxWidth="8"
                            >
                                <template #from>
                                    {{data['location_from']}}
                                </template>
                                <template #to>
                                    {{data['location_to']}}
                                </template>
                            </StyledFromTo>
                        </template>
                    </Column>
                    <Column field="passenger_speed" header="Speed">
                        <template #body="{data}">
                            <StyledFromTo fromSymbol="open-circle" toSymbol="filled-circle-dark">
                                <template #from>
                                    <span class="no-wrap">{{formatSpeed(data['normal_freight_speed'], data['normal_passenger_speed'])}}</span>
                                </template>
                                <template #to>
                                    <span class="no-wrap">{{formatSpeed(data['freight_speed'], data['passenger_speed'])}}</span>
                                </template>
                            </StyledFromTo>
                        </template>
                    </Column>  
                    <Column field="delivery_unit" header="DU">
                        <template #body="{data}">
                            <span v-if="data.delivery_unit === 'Lanc&Cumbria'">Lanc & Cumbria</span>
                            <span v-else> {{ data.delivery_unit }}</span>
                        </template>
                    </Column>
                    <Column field="track_id" header="Line"/>
                    <Column field="reason" header="Reason">
                        <template #body="{data}">
                            <div v-html="formatReason(data['reason'])"></div>
                        </template>
                    </Column>    
                    <Column field="daily_impact_minutes" header="Daily time loss" 
                            :sortable="true">
                        <template #body="{data}">
                            <div class="results-cell">
                                {{formatImpact(data.daily_impact_minutes)}}
                                <span v-if="formatImpact(data.daily_impact_minutes)=='Unknown'">
                                    <TimelossExplainer :dailyTimeloss="true"/>
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column field="cumulative_impact_minutes" header="Cumulative time loss" 
                            :sortable="true">
                        <template #body="{data}">
                            <div class="results-cell">
                                {{formatImpact(data.cumulative_impact_minutes)}}
                                <span v-if="formatImpact(data.cumulative_impact_minutes)=='Unknown'">
                                    <TimelossExplainer :dailyTimeloss="false"/>
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column field="removal_date" header="Removal Date"
                            :sortable="true">
                        <template #body="{data}">
                            <div>
                                {{ data.removal_date != new Date('9000-01-01').toDateString() ? 
                                formatEndDate(data.removal_date) : 'User Not Specified' }}
                            </div>
                        </template>    
                    </Column>
                </DataTable>
            </div>
        </template>
        <template #modalBody>
            <div v-if="loading">
            LOADING
            </div>
            <div v-else class="scroll-content">
                <div class="filters-row">
                    <b>Filters</b>
                    <MultiSelectDropdown title="Ref No." class="filter"
                        v-model:filterValues="filters.reference.value"
                        :itemList="referenceNumbers"
                        :width="7"
                    />
                    <MultiSelectDropdown title="Delivery unit" class="filter"
                        v-model:filterValues="filters.delivery_unit.value"
                        :itemList="duOptions"
                        :width="8"
                    />
                    <MultiSelectDropdown title="Reason" class="filter"
                        v-model:filterValues="filters.reason.value"
                        :itemList="reasonOptions"
                        :width="10"
                    />
                    <MultiSelectDropdown title="ESR/TSR" class="filter"
                        v-model:filterValues="filters.type.value"
                        :itemList="typeOptions"
                        :width="6"
                    />
                </div>
                <DataTable :value="data" :autoLayout="true"
                        class="p-datatable-sm speeds-table"
                        v-model:filters="filters" filterDisplay="menu"
                        sortField="daily_impact" :sortOrder="-1"
                >
                    <template #empty>
                        No speed restrictions found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <Column field="reference" header="Ref No.">
                        <template #body="{data}">
                            <button @click="referenceNumberClicked(data['reference'])" class="ref-button"
                            :class="{'active': isActiveSpeedRestriction(data.reference), 'esr' : isEsr(data.type), 'tsr' : !isEsr(data.type)}">
                                {{putNewlineInReference(data['reference'])}}
                            </button>
                        </template>
                    </Column>
                    <Column field="location_from" header="Location">
                        <template #body="{data}">
                            <StyledFromTo fromSymbol="filled-circle-light" toSymbol="filled-square-dark">
                                <template #from>
                                    {{data['location_from']}}
                                </template>
                                <template #to>
                                    {{data['location_to']}}
                                </template>
                            </StyledFromTo>
                        </template>
                    </Column>
                    <Column field="delivery_unit" header="DU">
                        <template #body="{data}">
                            <span v-if="data.delivery_unit === 'Lanc&Cumbria'">Lanc & Cumbria</span>
                            <span v-else> {{ data.delivery_unit }}</span>
                        </template>
                    </Column>
                    <Column field="track_id" header="Line"/>
                    <Column field="reason" header="Reason">
                        <template #body="{data}">
                            <div v-html="formatReason(data['reason'])"></div>
                        </template>
                    </Column>
                    <Column field="passenger_speed" header="Speed">
                        <template #body="{data}">
                            <StyledFromTo fromSymbol="open-circle" toSymbol="filled-circle-dark">
                                <template #from>
                                    <span class="no-wrap">{{formatSpeed(data['normal_freight_speed'], data['normal_passenger_speed'])}}</span>
                                </template>
                                <template #to>
                                    <span class="no-wrap">{{formatSpeed(data['freight_speed'], data['passenger_speed'])}}</span>
                                </template>
                            </StyledFromTo>
                        </template>
                    </Column>    
                    <Column field="daily_impact_minutes" header="Daily time loss" 
                            :sortable="true">
                        <template #body="{data}">
                            <div class="results-cell">
                                {{formatImpact(data.daily_impact_minutes)}}
                            </div>
                        </template>
                    </Column>
                    <Column field="cumulative_impact_minutes" header="Cumulative time loss" 
                            :sortable="true">
                        <template #body="{data}">
                            <div class="results-cell">
                                {{formatImpact(data.cumulative_impact_minutes)}}
                            </div>
                        </template>
                    </Column>
                    <Column field="removal_date" header="Removal Date"
                            :sortable="true">
                        <template #body="{data}">
                            <div>
                                {{ data.removal_date != new Date('9000-01-01').toDateString() ? 
                                formatEndDate(data.removal_date) : 'User Not Specified' }}
                            </div>
                        </template>    
                    </Column>
                    <Column field="tsr_year" header="TSR year"/>
                    <Column field="tsr_status" header="TSR status"/>
                    <Column field="line_affected" header="Line affected"/>
                    <Column field="miles_from" header="Miles from"/>
                    <Column field="chains_from" header="Chains from"/>
                    <Column field="miles_to" header="Miles to"/>
                    <Column field="chains_to" header="Chains to"/>
                    <Column field="manual_start_date" header="Manual start date"/>
                    <Column field="from_date" header="From date"/>
                    <Column field="to_date" header="To date"/>
                    <Column field="sprint_removal_date" header="SPRINT Removal Date"/>                    
                    <Column field="p_coded" header="P coded"/>
                    <Column field="started_as_esr" header="Started as esr"/>
                    <Column field="control_ref" header="Control ref"/>
                    <Column field="requestor" header="Requestor"/>
                    <Column field="responsible_manager" header="Responsible manager"/>
                    <Column field="line_name" header="Line name"/>
                    <Column field="general_remarks" header="General remarks"/>
                    <Column field="created_by" header="Created by"/>
                    <Column field="created_date" header="Created date"/>
                    <Column field="updated_by" header="Updated by"/>
                    <Column field="updated_date" header="Updated date"/>
                    <Column field="location_from_elr" header="ELR (Location from)"/>
                    <Column field="location_to_elr" header="ELR (Location to)"/>
                    <Column field="status" header="Status"/>
                    <Column field="category" header="Category"/>
                    <Column field="type" header="Type"/>
                    <Column field="route" header="Route"/>
                    <Column field="region" header="Region"/>
                    <Column field="actions" header="Action">
                        <template #body = "{data}">
                            <div class="results-cell action">
                                {{ data.actions }}
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from 'src/components/home/common/Card.vue'
import DataTable from "primevue/datatable/sfc";
import Column from "primevue/column/sfc";
import {FilterMatchMode} from 'primevue/api';
import DownloadSpeedRestrictionsTableButton from './DownloadSpeedRestrictionsTableButton.vue'
import StyledFromTo from 'src/components/home/common/StyledFromTo.vue'
import rnc from "src/functions/reference_number_conversion.js"
import format from "src/functions/formatter.js"
import checker from "src/functions/sr_type_checker.js"
import MultiSelectDropdown from "src/components/home/common/MultiSelectDropdown.vue"
import TimelossExplainer from "src/components/home/common/TimelossExplainer.vue"

export default {
    name: 'SpeedRestrictionRegister',
    components: {
        Card,
        DataTable,
        Column,
        DownloadSpeedRestrictionsTableButton,
        StyledFromTo,
        MultiSelectDropdown,
        TimelossExplainer
    },
    props: {
        tableData: {
            type: Promise,
            required: true
        }
    },
    data() {
        return {
            filters: {
                "reference": {value: [], matchMode: FilterMatchMode.IN},
                "delivery_unit": {value: [], matchMode: FilterMatchMode.IN},
                "reason": {value: [], matchMode: FilterMatchMode.IN},
                "type": {value: [], matchMode: FilterMatchMode.IN},
            },
            loading: true,
            data: null
        }
    },
    computed: {
        referenceNumbers() {
            return this.data.map(row => row.reference)
        },
        lineOptions() {
            // Gets unique options for line value to select from in the table filter
            let line_options = []
            let found_options = []
            for (let row of this.data) {
                // Check if 'line' value is not yet in options list and add it if not
                if (found_options.indexOf(row.LINE) == -1) {
                    line_options.push({"track_id": row.track_id})
                    found_options.push(row.track_id)
                }
            }
            return line_options
        },
        duOptions() {
            // Gets unique options for delivery unit value to select from in the table filter
            let du_options = []
            for (let row of this.data) {
                // Check if 'delivery_unit' value is not yet in options list and add it if not
                if (du_options.indexOf(row.delivery_unit) == -1) {
                    du_options.push(row.delivery_unit)
                }
            }
            du_options.sort()
            return du_options
        },
        reasonOptions() {
            // Gets unique options for reason value to select from in the table filter
            let reason_options = []
            for (let row of this.data) {
                // Check if 'reason' value is not yet in options list and add it if not
                if (reason_options.indexOf(row.reason) === -1) {
                    reason_options.push(row.reason)
                }
            }
            reason_options.sort()
            return reason_options
        },
        typeOptions() {
            // Gets unique options for type value to select from in the table filter
            let type_options = []
            for (let row of this.data) {
                // Check if 'type' value is not yet in options list and add it if not
                if (type_options.indexOf(row.type) === -1) {
                    type_options.push(row.type)
                }
            }
            type_options.sort()
            return type_options
        }
    },
    watch: {
        tableData(promise) {
            this.loading = true
            promise.then(data => {
                this.clearFilters()
                this.data = this.addRemovalDateField(data)
                this.loading = false
            })
        }
    },
    methods: {
        /**
         * Inserts a newline after a '/' for the given string
         * @param {String} reference 
         * @returns {String} 
         */
        putNewlineInReference(reference) {
            return reference.split("/").join("/\n")
        },
        formatDate(date) {
            let day = date.getDate().toString()
            if (day.slice(-1) == "1" && day != "11") {
                day += "st"
            }
            else if (day.slice(-1) == "2" && day != "12") {
                day += "nd"
            }
            else if (day.slice(-1) == "3" && day != "13") {
                day += "rd"
            }
            else {
                day += "th"
            }
            let month = date.toLocaleString("en-UK", {month: "long"});
            let year = date.getFullYear()
            let formatted_date = `${day} ${month} ${year}`
            return formatted_date
        },
        formatEndDate(date){
            return format.formatDate(date)
        },
        isActiveSpeedRestriction(reference_number) {
            return (this.$store.state.activeIndividualSpeedRestriction === reference_number)
        },
        formatImpact(impactNum) {
            // Reformates time loss values to either add 'minute/s' or replace with 'unknown'
            // Unknown time loss values are stored as -1 for easy sorting,
            // but displayed on the table as 'Unknown'
            if (impactNum == null) {
                impactNum = "Unknown"
            }
            else {
                impactNum = impactNum.toLocaleString("en-UK")
                if (impactNum == 1) {
                    impactNum += " minute"
                }
                else {
                    impactNum += " minutes"
                }
            }
            return impactNum
        },
        isEsr(type) {
            return checker.isEsr(type)
        },
        formatSpeed(freightSpeed, passengerSpeed) {

            if (!freightSpeed && !passengerSpeed) return "Unknown"

            if (freightSpeed === passengerSpeed) {
                return `${freightSpeed} mph`
            }

            return `${freightSpeed}/${passengerSpeed} mph`
        },
        addRemovalDateField(data){
            for(let line in data){
                if(data[line].to_date != null){
                    data[line].removal_date = data[line].to_date
                }
                else if(data[line].sprint_removal_date != null){
                    data[line].removal_date = data[line].sprint_removal_date
                }
                else{
                    // date set articifically high for sorting purposes (making nulls last).
                    // TODO: look into sortField and customSort primevue attributes in 
                    // order to implement custom sort method. Github docs not very clear.
                    data[line].removal_date = new Date('9000-01-01').toDateString()
                }
            }
            return data
        },
        referenceNumberClicked(referenceNumber) {
            // set router to IndividualContent with reference as parameter
            referenceNumber = rnc.referenceToUriReference(referenceNumber)
            this.$router.push(`/home/individual/overview/${referenceNumber}`)
        },
        clearFilters() {
            // get filters
            let filters = this.filters
            // loop through and set value to null for each filter
            for (let key in filters) {
                filters[key].value = []
            }
            // reassign cleared filters
            this.filters = filters
        },
        formatReason(string) {
            return format.formatReason(string)
        },
        /**
         * Adds the full text of an element as a hover label if it is overflowing
         * @param {MouseEvent}
         */
        setHoverLabelIfOverflowing(event) {
            let element = event.target;
            if (element.offsetWidth < element.scrollWidth && !element.title) {
                element.title = element.textContent
            }
        }
    },
    created() {
        this.tableData.then(data => {
            this.data = this.addRemovalDateField(data)
            this.loading = false
        })
    }
}
</script>


<style scoped>

/* Alternate grey background on rows of table */
:deep(.body) {
    padding: 0;
}
.scroll-content {
    height: 100%;
    overflow: auto;
}
.speeds-table :deep(th) {
    font-size: 1rem !important;
}
:deep(.p-datatable-tbody > tr >td:first-child), :deep(.p-datatable-thead > tr > th:first-child) {
    padding-left: 1.5rem;
}
:deep(.p-datatable-tbody > tr) {
    height: 6rem;
}
:deep(.p-datatable-tbody > tr:nth-child(2n)) {
    background-color: var(--colour-2);
}
:deep(.p-datatable-tbody > tr:nth-child(2n) .ref-button) {
    border-color: var(--colour-2);
}
.key {
    display: inline; 
    margin-right: 0.7rem;
}
.ref-button {
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: bold;
    border-color: var(--colour-4);
    border-style: solid;
    border-bottom-width: 2px;
    white-space: pre;
    max-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.esr{
    color: var(--colour-7);
}
.tsr{
    color: var(--colour-1);
}
.ref-button:hover {
    cursor: pointer;
    border-style: solid;
    border-bottom-width: 2px;
    border-color: var(--colour-1) !important;
    /* color: var(--colour-1); */
}
/* .ref-button.active {
    border-color: var(--colour-2);
} */

.results-cell {
    text-align: left;
    padding-right: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}
.action {
    min-width: 75rem;
}

.speedscell {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.speedimposed, .speednormal{
    border: 0.1rem solid;
    padding: 0.1rem;
    border-radius: 5px;
    text-align: center;
    width: 7rem;
}

.multiselect-input {
    border-radius: 10px;
    background-color: var(--colour-3);
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.multiselect-input:not(:first-child) {
    margin-top: 0.2rem;
}

.multiselect-input i {
    margin-left: 0.5rem;
}

.multiselect-input-container {
    max-height: 10rem;
    overflow-y: scroll;
}
.no-wrap {
    white-space: nowrap;
}
/* 
    Filters row styling
*/
.filters-row {
    font-size: 1rem;
    margin-left: 1rem;
    padding: 1rem 0 1rem 0.5rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--colour-2);
}
.filters-row > b {
    margin-right: 2rem;
}
.filters-row > .filter {
    margin-right: 1rem;
}
</style>