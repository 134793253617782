<!-- HomePage of the app -->

<template>
  <div>
    <HeaderBar/>
    <div id="page-content">
          <router-view></router-view>
    </div>
  </div>
</template>

<script>
import HeaderBar from './headerbar/HeaderBar.vue'

export default {
  name: 'HomePage',
  components: {
      HeaderBar
  }
}
</script>

<style scoped>
#page-content {
    padding: 6rem 1.5vw 1rem 1.5vw;  /* Top padding accounts for header bar (5rem) */
    height: 100vh;
}
</style>
