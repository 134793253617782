<!-- Displays content for the individual page  -->

<template>
    <div id="individual-content">
        <!-- Header bar for displaying reference number, location, speed, and reason -->
        <div id="header-content">
            <div class="speed-restriction-info">
                <span v-if="loading.header"> LOADING </span>
                <SearchDropdown v-else
                    :headerText="`${headerData.route ? headerData.route : 'Unknown'} - ${referenceNumber}${notCurrentText}`"
                    :activeItem="`${referenceNumber} - ${headerData.location_from} to ${headerData.location_to}`"
                    :itemListPromise="referenceList()" @itemSelected="referenceClicked" :headerSize="2"
                    :headerWeight="600" :headerMaxWidth="headerData.status === 'Current' ? 40 : 80" :headerColour="headerData.status === 'Current' ? assignColourToSr(headerData.type) : 'var(--colour-13)'
                    " :itemsNeedUpperCasing="false" />
                <span v-if="loading.header"> LOADING </span>
                <span v-else id="header-data">
                    <span class="location">
                        {{ headerData.location_from }} to {{ headerData.location_to }}
                    </span>
                    <div class="speed-and-reason">
                        <span class="speed-imposed">
                            {{ formatSpeed(
                    headerData.normal_freight_speed,
                    headerData.normal_passenger_speed)
                            }}mph
                            to
                            {{ formatSpeed(
                    headerData.freight_speed,
                    headerData.passenger_speed)
                            }}mph
                        </span>
                        <i class="pi pi-circle-on" />
                        <span class="reason">{{ headerData.reason }}</span>
                    </div>
                    <div class="upcoming-or-removed" v-if="headerData && headerData.status === 'Upcoming'">
                        Speed planned for {{ formatDate(headerData.from_date) }}
                        (from PPS)
                    </div>
                    <div class="upcoming-or-removed" v-else-if="headerData && headerData.status === 'Removed'">
                        Speed removed on {{ formatDate(headerData.to_date) }}
                        (from PPS)
                    </div>
                </span>
            </div>
            <!-- Flex container for kpis -->
            <div id="kpis-container" v-if="isCurrent">
                <Kpi :svgSource="require('@/assets/history.svg')">
                    <template #value>Time loss per day</template>
                    <template #title v-if="loading.timeLossPerDay">
                        LOADING
                    </template>
                    <template #title v-else>
                        {{formatImpact(
                        kpiData.timeLossPerDay.daily_impact_minutes
                        )}}
                        <span v-if="!kpiData.timeLossPerDay.daily_impact_minutes">
                            <TimelossExplainer :dailyTimeloss="true" />
                        </span>
                    </template>
                    <template #subtitle_1 v-if="loading.timeLossPerDay_Passenger">
                        LOADING
                    </template>
                    <template #subtitle_1 v-else>
                        Passenger trains:
                        <span v-if="kpiData.timeLossPerDay.daily_impact_minutes">
                            {{formatImpact(
                            kpiData.timeLossPerDay_Passenger.daily_impact_minutes
                            )}}
                        </span>
                        <span v-else>
                            Unknown
                        </span>
                    </template>
                    <template #subtitle_2 v-if="loading.timeLossPerDay_Freight">
                        LOADING
                    </template>
                    <template #subtitle_2 v-else>
                        Freight trains:
                        <span v-if="kpiData.timeLossPerDay.daily_impact_minutes">
                            {{formatImpact(
                            kpiData.timeLossPerDay_Freight.daily_impact_minutes
                            )}}
                        </span>
                        <span v-else>
                            Unknown
                        </span>
                    </template>
                    <template #description v-if="loading.timeLossPerDay">
                        LOADING
                    </template>
                    <template #description v-else>
                        Cumulative time loss:
                        {{formatImpact(
                        kpiData.timeLossPerDay.cumulative_impact_minutes
                        )}}
                    </template>
                </Kpi>
                <div class="spacer"></div>
                <Kpi :svgSource="require('@/assets/calendar.svg')">
                    <template #value>Active time</template>
                    <template #title v-if="loading.activeTime">LOADING</template>
                    <template #title v-else>
                        {{formatWeeks(kpiData.activeTime.weeks_active)}}
                    </template>
                    <template #description v-if="loading.activeTime">
                        LOADING
                    </template>
                    <template #description v-else>
                        PPS removal date:
                        {{formatDate(kpiData.activeTime.planned_removal_date)}}
                        <br>
                        <span v-if="kpiData.activeTime.sprint_removal_date != null ">
                            SPRINT removal date:
                            {{formatDate(kpiData.activeTime.sprint_removal_date) }}
                        </span>
                    </template>
                </Kpi>
                <div class="spacer"></div>
                <Kpi :svgSource="require('@/assets/train.svg')">
                    <template #value>Impacted daily</template>
                    <template #title v-if="loading.trainsImpactedPerDay">
                        LOADING
                    </template>
                    <template #title v-else>
                        {{formatTrainCount(
                        kpiData.trainsImpactedPerDay.trains_impacted_per_day
                        )}}
                        <span v-if="!kpiData.trainsImpactedPerDay.trains_impacted_per_day">
                            <TimelossExplainer :dailyTimeloss="true" />
                        </span>
                    </template>
                    <template #subtitle_1 v-if="loading.trainsImpactedPerDay_Passenger">
                        LOADING
                    </template>
                    <template #subtitle_1 v-else>
                        Passenger trains:
                        <span v-if="kpiData.trainsImpactedPerDay.trains_impacted_per_day">
                            {{formatTrainCount(
                            kpiData.trainsImpactedPerDay_Passenger.trains_impacted_per_day
                            )}}
                        </span>
                        <span v-else>
                            Unknown
                        </span>
                    </template>
                    <template #subtitle_2 v-if="loading.trainsImpactedPerDay_Freight">
                        LOADING
                    </template>
                    <template #subtitle_2 v-else>
                        Freight trains:
                        <span v-if="kpiData.trainsImpactedPerDay.trains_impacted_per_day">
                            {{formatTrainCount(
                            kpiData.trainsImpactedPerDay_Freight.trains_impacted_per_day
                            )}}
                        </span>
                        <span v-else>
                            Unknown
                        </span>
                    </template>
                    <template #description v-if="loading.trainsImpactedPerDay">
                        LOADING
                    </template>
                    <template #description v-else>
                        Most impacted:
                        {{formatMostImpacted(
                        kpiData.trainsImpactedPerDay.most_impacted_toc
                        )}}
                    </template>
                </Kpi>
            </div>
        </div>
        <div id="data-content">
            <!-- Tab bar for switching views -->
            <div id="tab-bar">
                <button @click="tabClick('Overview')" :class="{
                    active: currentTabComponent.toLowerCase() == 'overview'
                }" v-if="isCurrent">
                    Overview
                </button>
                <button @click="tabClick('Details')" :class="{
                    active: currentTabComponent.toLowerCase() == 'details'
                }">
                    Details
                </button>
                <button @click="tabClick('UserInput')" :class="{
                    active: currentTabComponent.toLowerCase() == 'userinput'
                }">
                    User Input
                </button>
                <button @click="tabClick('Management')" :class="{
                    active: currentTabComponent.toLowerCase() == 'management'
                }">
                    Management
                </button>
                <button @click="tabClick('Analysis')" :class="{
                    active: currentTabComponent.toLowerCase() == 'analysis'
                }" v-if="isCurrent">
                    Analysis
                </button>
                <button @click="tabClick('Journeys')" :class="{
                    active: currentTabComponent.toLowerCase() == 'journeys'
                }" v-if="isCurrent">
                    Journeys
                </button>
            </div>
            <!-- Tab content selected by tab bar -->
            <div id="tab-content">
                <keep-alive>
                    <component :is="currentTabComponent" @inputUpdated="getKpiActiveTimeData()">
                    </component>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import Kpi from "../common/Kpi.vue"
import Overview from "./overview/Overview.vue"
import Details from "./Details.vue"
import UserInput from "./user_input/UserInput.vue"
import Management from "./management/Management.vue"
import Analysis from "./analysis/Analysis.vue"
import Journeys from "./Journeys.vue"
import axios from "axios";
import rnc from "src/functions/reference_number_conversion.js"
import format from "src/functions/formatter.js"
import SearchDropdown from "src/components/home/common/SearchDropdown.vue";
import TimelossExplainer from "src/components/home/common/TimelossExplainer.vue"

export default {
    name: 'IndividualContent',
    components: {
        Kpi,
        Overview,
        Details,
        UserInput,
        Management,
        Analysis,
        Journeys,
        SearchDropdown,
        TimelossExplainer,
    },
    tabComponents: [
        "overview",
        "details",
        "userinput",
        "management",
        "analysis",
        "journeys"

    ],
    data() {
        return {
            currentTabComponent: "Overview",
            loading: {
                timeLossPerDay: true,
                activeTime: true,
                trainsImpactedPerDay: true,
                header: true
            },
            headerData: null,
            kpiData: {
                timeLossPerDay: null,
                activeTime: null,
                trainsImpactedPerDay: null
            }
        }
    },
    computed: {
        referenceNumber() {
            // reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            // TODO: just grab any current speed restriction if ref is none
            return ref
        },
        /**
         * Returns a string indicating the status of a reference if not current
         */
        notCurrentText() {
            let notCurrentText = ""
            if (this.headerData && !this.loading.header) {
                const status = this.headerData.status.toLowerCase()
                if (status === "removed") {
                    return " (removed)"
                }
                else if (status === "upcoming") {
                    return " (emerging)"
                }
                else if (status === "unconfirmed") {
                    return " (unconfirmed)"
                }
            }
            return notCurrentText
        },
        /**
         * Returns boolean indicating whether speed restrictions is 'Current'
         */
        isCurrent() {
            let isCurrent = false
            if (
                this.headerData
                && this.headerData.status.toLowerCase() === "current"
            ) {
                isCurrent = true
            }
            return isCurrent
        }
    },
    watch: {
        "$route.params": function (params) {
            if (params.tab || params.reference) {
                // Set the tab and reference if the url changes
                let tab = params.tab
                let replace = false
                // if the tab is invalid, set the tab to 'Details'
                if (
                    rnc.uriReferenceToReference(params.reference) === this.referenceNumber
                    && this.headerData
                    && !this.checkValidTab(
                        this.headerData.status,
                        params.tab
                    )
                ) {
                    tab = "Details"
                    replace = true
                }
                this.setTabAndReference(params.reference, tab, replace)
            }
        },
        referenceNumber: function () {
            this.getData()
        }
    },
    methods: {
        formatWeeks(weeks) {
            if (weeks == null) {
                return "Unknown"
            }
            else {
                return format.formatUnit(weeks, "week")
            }
        },
        formatImpact(impactNum) {
            // Reformates time loss values to either add 'minute/s'
            // or replace with 'unknown'
            if (impactNum == null) {
                return "Unknown"
            }
            else {
                impactNum = impactNum.toLocaleString("en-UK")
                return format.formatUnit(impactNum, "minute")
            }
        },
        formatTrainCount(trainCount) {
            if (trainCount == null) {
                return "Unknown"
            }
            else {
                return format.formatUnit(trainCount, "train")
            }
        },
        formatMostImpacted(mostImpacted) {
            if (mostImpacted == null) {
                return "Unknown"
            }
            else {
                return mostImpacted
            }
        },
        formatSpeed(freightSpeed, passengerSpeed) {
            if (freightSpeed === passengerSpeed) {
                return freightSpeed
            }
            else {
                return `${freightSpeed}/${passengerSpeed}`
            }
        },
        formatDate(date) {
            return format.formatDate(date)
        },
        assignColourToSr(type) {
            if (type == 'ESR') {
                return "var(--colour-7)"
            }
            return "var(--colour-1)"
        },
        getKpiTimeLossData() {
            // Gets data for the time loss kpi

            this.loading.timeLossPerDay = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-time-loss-per-day`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.timeLossPerDay = response.data
                    this.loading.timeLossPerDay = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getKpiPassengerTrainTimeLossData() {
            // Gets data for the passenger trains time loss kpi

            this.loading.timeLossPerDay_Passenger = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-passenger-trains-time-loss-per-day`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.timeLossPerDay_Passenger = response.data
                    this.loading.timeLossPerDay_Passenger = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getKpiFreightTrainTimeLossData() {
            // Gets data for the freight trains time loss kpi

            this.loading.timeLossPerDay_Freight = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-freight-trains-time-loss-per-day`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.timeLossPerDay_Freight = response.data
                    this.loading.timeLossPerDay_Freight = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getKpiActiveTimeData() {
            // gets data for the active time kpi

            this.loading.activeTime = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-active-time`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.activeTime = response.data
                    this.loading.activeTime = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getKpiTrainsImpactedData() {
            // gets data for the trains impacted kpi

            this.loading.trainsImpactedPerDay = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-trains-impacted-per-day`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.trainsImpactedPerDay = response.data
                    this.loading.trainsImpactedPerDay = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getKpiPassengerTrainsImpactedData() {
            // gets data for the passenger trains impacted kpi

            this.loading.trainsImpactedPerDay_Passenger = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-passenger-trains-impacted-per-day`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.trainsImpactedPerDay_Passenger = response.data
                    this.loading.trainsImpactedPerDay_Passenger = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getKpiFreightTrainsImpactedData() {
            // gets data for the freight trains impacted kpi

            this.loading.trainsImpactedPerDay_Freight = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/kpi-freight-trains-impacted-per-day`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.kpiData.trainsImpactedPerDay_Freight = response.data
                    this.loading.trainsImpactedPerDay_Freight = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getHeaderData() {
            // gets data for the header
            this.loading.header = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/header-data`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.headerData = response.data
                    this.loading.header = false
                    const tabValid = this.checkValidTab(
                        this.headerData.status,
                        this.currentTabComponent
                    )
                    if (!tabValid) {
                        this.tabClick("Details", true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        /**
         * Checks that a valid tab is selected if the reference isn't Current
         * 
         * Only the details and managements tabs can be viewed if a reference
         * is not current. if another tab is selected it redirects to 'Details'
         * 
         * @param {String} status - the status of the current speed restriction
         * @param {String} tabComponent - the currently selected tab component
         */
        checkValidTab(status, tabComponent) {
            status = status.toLowerCase()
            tabComponent = tabComponent.toLowerCase()
            if (
                status !== "current"
                && (
                    tabComponent !== "details"
                    && tabComponent !== "management"
                    && tabComponent !== "userinput"
                )
            ) {
                return false
            }
            else {
                return true
            }
        },
        /**
         * Gets a list of references for the dropdown selection
         */
        referenceList() {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/reference-list`,
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    return response.data.map((element) => {
                        return element.reference_and_location
                    })
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        referenceClicked(referenceAndLocation) {
            this.$router.push(
                `/home/individual/${this.currentTabComponent}/${rnc.referenceToUriReference(this.referenceAndLocationToReference(referenceAndLocation))}`
            )
        },

        /**
         * extracts reference from a reference and location string
         */
        referenceAndLocationToReference(referenceAndLocation) {
            // some references have newlines in them
            return referenceAndLocation.match(/^((?:.|\n)+)\s-/)[1]
        },
        getData() {
            // Gets data for the kpis

            this.getKpiTimeLossData()
            this.getKpiActiveTimeData()
            this.getKpiTrainsImpactedData()
            this.getHeaderData()
            this.getKpiPassengerTrainTimeLossData()
            this.getKpiFreightTrainTimeLossData()
            this.getKpiPassengerTrainsImpactedData()
            this.getKpiFreightTrainsImpactedData()
        },

        /**
         * Changes the tab component to the clicked tab, using the router
         * @param {string} componentString - string of the tab component to render
         */
        tabClick(componentString, replace = false) {
            if (replace) {
                this.$router.replace(`/home/individual/${componentString}/${rnc.referenceToUriReference(this.referenceNumber)}`)
            }
            else {
                this.$router.push(`/home/individual/${componentString}/${rnc.referenceToUriReference(this.referenceNumber)}`)
            }
        },

        /**
         * Uses the router parameters to set the reference number and tab
         *  component for the page
         */
        async setTabAndReference(reference, tab, replace = false) {
            /* In individual page, 'Current' active SRs (in blue) have 6 active tabs
             * -> Overview, Details, User Input, Management, Analysis, Journeys
             *
             * Inactive/removed speed restrictions only have 3 active tabs
             * -> Details, User Input, Management
             * 
             * By default, 'Overview' tab will load for speed restriction on first render of DOM.
             * 
             * If URL changes (for example, user toggles from an active SR to a removed 
             * SR in the 'Indidivual SR page' drop down) and the tab in the URL (eg. Analysis, Overview)
             * doesn't exist (ie. parameter doesn't appear in the $options.tabcomponents) it 
             * defaults to the tab specified in by argument 'tab'
             * -This is usually the 'Details' tab, see specified 'watch' property in component.
            */
            if (!tab || (this.$options.tabComponents.indexOf(tab.toLowerCase()) === -1 && !reference)) {
                reference = tab
                tab = "overview"
                replace = true
            }
            else if (this.$options.tabComponents.indexOf(tab.toLowerCase()) === -1 && reference) {
                tab = "overview"
                replace = true
            }

            this.currentTabComponent = tab
            if (reference) {
                // references are stored upper case for URL case insensitivity
                reference = reference.toUpperCase()
                this.$store.commit(
                    "setActiveIndividualSpeedRestriction",
                    rnc.uriReferenceToReference(reference)
                )
                replace = true
            }
            // if no reference, set to first reference in reference list
            else if (!this.referenceNumber) {
                let referenceList = await this.referenceList()
                this.$store.commit(
                    "setActiveIndividualSpeedRestriction",
                    rnc.referenceToUriReference(
                        this.referenceAndLocationToReference(
                            referenceList[0]
                        )
                    )
                )
                replace = true
            }
            if (replace) {
                this.$router.replace(
                    `/home/individual/${tab}/${rnc.referenceToUriReference(this.referenceNumber)}`
                )
            }
            else {
                this.$router.push(
                    `/home/individual/${tab}/${rnc.referenceToUriReference(this.referenceNumber)}`
                )
            }
        }
    },
    created() {
        this.$store.commit("setActiveHomePageTab", "IndividualContent")
        this.setTabAndReference(
            this.$route.params.reference,
            this.$route.params.tab
        )
        this.getData()
    }
}
</script>


<style scoped>
#individual-content {
    height: 100%;
}

#header-content {
    display: flex;
    justify-content: space-between;
    height: 6rem;
}

#data-content {
    height: calc(100% - 7rem);
    /* account for header size and tab bar margin */
}

.speed-restriction-info {
    display: flex;
    flex-direction: column;
}

#header-data {
    color: var(--colour-6);
    font-size: 1.2rem;
}

#header-data>.speed-and-reason {
    display: flex;
    align-items: center;
}

#header-data i {
    font-size: 0.4rem;
    color: var(--colour-6);
    margin: 0 0.5rem 0 0.5rem;
}

#header-data>.upcoming-or-removed {
    font-weight: 600;
}

/*----------------------------------------------------------------------*/
/*-------------------------- KPIs --------------------------------------*/
/*----------------------------------------------------------------------*/
#kpis-container {
    display: flex;
    flex-grow: 1;
    margin-left: 2rem;
}

#kpis-container :deep(.kpi) {
    box-shadow: none;
    background-color: transparent;
    border: 0;
    padding: 0;
}

#kpis-container :deep(.kpi .title) {
    color: var(--colour-1);
}

#kpis-container :deep(.kpi .value) {
    color: var(--colour-6);
    font-size: 0.8rem;
    font-weight: 400;
}

.spacer {
    height: 100%;
    background-color: var(--colour-11);
    width: 0.1rem;
    margin: 0 2rem 0 2rem;
}

/*----------------------------------------------------------------------*/

#tab-bar {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: var(--colour-6);
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#tab-bar>button {
    font-size: 1.6rem;
    border: 0;
    background-color: transparent;
    padding: 0;
    color: var(--colour-6);
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: var(--colour-2);
    padding-bottom: 1rem;
    font-family: "Inter";
}

#tab-bar>button:not(:last-child) {
    margin-right: 3rem;
}

#tab-bar>button:hover {
    cursor: pointer;
    border-color: var(--colour-6);
}

#tab-bar>button.active {
    font-weight: bold;
    border-color: var(--colour-6);

}

/* Sets tab conetnt to fill remaining area of screen */
#tab-content {
    width: 100%;
    height: calc(100% - 5.5rem);
    /* Accounts for tab bar */
}

#msg-data-not-available {
    color: var(--colour-6);
    font-size: 2rem;
    text-align: center;
    margin-top: 5rem;
}
</style>